import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ew-module",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"72"}},[_vm._v("mdi-folder-plus-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Create new module ")]),_c(VCardText,[_c(VRow,{staticClass:"mt-4"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Name","dense":""},model:{value:(_vm.newModuleName),callback:function ($$v) {_vm.newModuleName=$$v},expression:"newModuleName"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","label":"Description","dense":""},model:{value:(_vm.newModuleDescription),callback:function ($$v) {_vm.newModuleDescription=$$v},expression:"newModuleDescription"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":"","disabled":_vm.creating,"loading":_vm.creating},on:{"click":_vm.createModule}},[_vm._v(" Create ")])],1)],1)],1)],1),_vm._l((_vm.modules),function(module,i){return _c(VCol,{key:i},[_c(VBtn,{staticClass:"ew-module",attrs:{"color":"primary","link":"","to":{ name: 'module', params: { id: module.id } }}},[_vm._v(_vm._s(module.name)+" ")])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }