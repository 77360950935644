
import axios from "axios";
import { Vue, Component } from "vue-property-decorator";

@Component({ name: "exercises" })
export default class Exercises extends Vue {
  exercises = [];
  modules: any[] = [];
  selectedModuleId = "";

  async mounted() {
    this.exercises = await (
      await axios.get(`${process.env.VUE_APP_BASE_URL}api/exercises`)
    ).data;

    this.modules = await (
      await axios.get(`${process.env.VUE_APP_BASE_URL}api/modules`)
    ).data;

    this.selectedModuleId = this.modules[0].id;
  }

  goToExercise(exerciseId) {
    this.$router.push({
      name: "exercise",
      params: { id: exerciseId, moduleId: this.selectedModuleId },
    });
  }
}
