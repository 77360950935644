
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";

@Component({ name: "modules" })
export default class ModulesView extends Vue {
  modules: any[] = [];
  dialog: boolean | null = null;

  creating = false;
  newModuleName = "";
  newModuleDescription = "";

  async mounted() {
    this.modules = await (
      await axios.get(`${process.env.VUE_APP_BASE_URL}api/modules`)
    ).data;
  }

  async createModule() {
    try {
      this.creating = true;
      const createdModule = await (
        await axios.post(`${process.env.VUE_APP_BASE_URL}api/modules`, {
          name: this.newModuleName,
          description: this.newModuleDescription,
        })
      ).data;
      this.modules.push(createdModule);

      this.dialog = false;
      this.newModuleName = "";
      this.newModuleDescription = "";
    } catch {
      //
    } finally {
      this.creating = false;
    }
  }
}
