import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c(VContainer,[_c(VRow,[_c(VCol,[_vm._v("Module: "+_vm._s(_vm.module.name))])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"5"}},[_c(VTextField,{attrs:{"outlined":"","label":"Text","dense":""},model:{value:(_vm.wordText),callback:function ($$v) {_vm.wordText=$$v},expression:"wordText"}})],1),_c(VCol,{attrs:{"cols":"5"}},[_c(VTextField,{attrs:{"outlined":"","label":"Translate","dense":""},model:{value:(_vm.wordTranslate),callback:function ($$v) {_vm.wordTranslate=$$v},expression:"wordTranslate"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.creating,"loading":_vm.creating},on:{"click":_vm.addWord}},[_vm._v("Add")])],1)],1),_vm._l((_vm.module.words),function(word,i){return _c(VRow,{key:i},[_c(VCol,{attrs:{"cols":"5"}},[_vm._v(_vm._s(word.text))]),_c(VCol,{attrs:{"cols":"5"}},[_vm._v(_vm._s(word.translate))]),_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.removeWord(word.id)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('hr')],1)})],2):_c('span',[_vm._v("loading...")])
}
var staticRenderFns = []

export { render, staticRenderFns }