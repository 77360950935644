import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,_vm._l((_vm.exercises),function(exercise,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"4"}},[_c(VCard,{attrs:{"color":"light-blue lighten-5","light":""}},[_c(VCardTitle,[_c('p',{staticStyle:{"word-break":"normal"}},[_vm._v(_vm._s(exercise.title))])]),_c(VCardText,[_vm._v(_vm._s(exercise.description))]),_c(VSelect,{staticClass:"ma-2",attrs:{"items":_vm.modules,"dense":"","outlined":"","item-text":"name","item-value":"id","label":"Words from Module"},model:{value:(_vm.selectedModuleId),callback:function ($$v) {_vm.selectedModuleId=$$v},expression:"selectedModuleId"}}),_c(VCardActions,{staticStyle:{"align-items":"baseline"}},[_c(VBtn,{attrs:{"color":"primary","link":""},on:{"click":function($event){return _vm.goToExercise(exercise.id)}}},[_vm._v(" Let's go ")])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }