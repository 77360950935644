
import axios from "axios";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class Exercise extends Vue {
  exercise: any = [];
  currentWord = 0;
  answer: any = null;
  result: boolean | null = null;
  showErrorAlert = false;
  showCorrectWord = false;

  async mounted() {
    this.exercise = await (
      await axios.get(
        `${process.env.VUE_APP_BASE_URL}api/exercises/${this.$route.params.id}/module/${this.$route.params.moduleId}`
      )
    ).data;
  }

  moveNext() {
    this.currentWord++;
    this.answer = null;
    this.result = null;

    this.showCorrectWord = false;
  }

  verifyAnswer() {
    this.result = this.exercise![this.currentWord].word.id == this.answer?.id;
  }

  verifyAnswer3Exercise() {
    if (!this.answer) this.answer = "";

    this.result =
      this.exercise![this.currentWord].word.text.toUpperCase() ===
      this.answer.trim()?.toUpperCase();
  }

  @Watch("result")
  onAnswerChanged() {
    if (this.result === false) {
      this.showErrorAlert = true;
      this.showCorrectWord = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1000);
    }
  }
}
