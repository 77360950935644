
import axios from "axios";
import { Vue, Component } from "vue-property-decorator";

type Word = {
  id: string;
  text: string;
  translate: string;
};

type Module = {
  id: string;
  name: string;
  description: string;
  words: Word[];
};

@Component({ name: "module" })
export default class ModuleDetails extends Vue {
  module: Module | null = null;
  loading = true;
  creating = false;

  wordText = "";
  wordTranslate = "";

  async mounted() {
    try {
      this.module = await (
        await axios.get(
          `${process.env.VUE_APP_BASE_URL}api/modules/${this.$route.params.id}`
        )
      ).data;
    } finally {
      this.loading = false;
    }
  }

  async addWord() {
    if (!(this.wordText && this.wordTranslate)) return;

    try {
      this.creating = true;

      const createdWord = await (
        await axios.post<Word>(`${process.env.VUE_APP_BASE_URL}api/words`, {
          moduleId: this.module.id,
          text: this.wordText,
          translate: this.wordTranslate,
        })
      ).data;

      this.module.words.push(createdWord);
      this.wordText = "";
      this.wordTranslate = "";
    } finally {
      this.creating = false;
    }
  }

  async removeWord(wordId: string) {
    await axios.delete(`${process.env.VUE_APP_BASE_URL}api/words/${wordId}`);

    this.module.words = this.module.words.filter((x) => x.id !== wordId);
  }
}
