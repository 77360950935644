import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_vm._v("Home")]),_c(VSpacer),_c(VBtn,{attrs:{"href":"https://github.com/vuetifyjs/vuetify/releases/latest","target":"_blank","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Latest Release")]),_c(VIcon,[_vm._v("mdi-open-in-new")])],1)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }