import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{staticStyle:{"text-align":"-webkit-center"},attrs:{"justify":"center"}},[_c(VCol,{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c(VCard,{attrs:{"width":"500px","color":"light-blue lighten-5"}},[_c(VImg,{attrs:{"src":require("../assets/modules.jpeg"),"height":"272"}}),_c(VCardTitle,[_vm._v("Modules")]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","link":"","to":{ name: 'modules' }}},[_vm._v("Navigate")])],1)],1)],1),_c(VCol,[_c(VCard,{attrs:{"width":"500px","color":"light-blue lighten-5"}},[_c(VImg,{attrs:{"src":require("../assets/exercises.jpeg"),"max-height":"272"}}),_c(VCardTitle,[_vm._v("Exercises")]),_c(VCardActions,[_c(VBtn,{attrs:{"link":"","color":"primary","to":{ name: 'exercises' }}},[_vm._v("Navigate ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }